/* eslint-disable camelcase */
import { gql } from '@apollo/client'

import {
  Lots_lotsV2_results,
  Lots_lotsV2_results_riceLot,
  Lots_lotsV2_results_riceLot_calendarV3 as CalendarDate,
  Lots_lotsV2_results_area,
  Lots_lotsV2_results_location,
} from './types/Lots'

type RiceLot = Omit<Lots_lotsV2_results_riceLot, 'calendarV3'> & {
  calendar: CalendarDate[]
}

type Lot = Omit<Lots_lotsV2_results, 'riceLot' | 'area' | 'location'> & {
  riceLot: RiceLot
  area: Lots_lotsV2_results_area
  location: Lots_lotsV2_results_location
}

type LotWithArea = Omit<Lots_lotsV2_results, 'area'> & {
  area: Lots_lotsV2_results_area
  location: Lots_lotsV2_results_location
}
interface LotResults extends Omit<Lots_lotsV2_results, 'results'> {
  results: LotWithArea[]
}
interface Lots {
  lotsV2: LotResults
}

export type { Lot, CalendarDate, Lots }

export type { LotsVariables } from './types/Lots'

export const LOTS = gql`
  query Lots(
    $seasonId: Int!
    $fieldId: Int!
    $toYearMonth: String
    $fromYearMonth: String
    $includeMapUrls: Boolean!
  ) {
    lotsV2(seasonId: $seasonId, fieldId: $fieldId, discardWithoutArea: true) {
      results {
        id
        name
        size
        area {
          type
          coordinates
        }
        variety {
          id
          name
          isGrainHumidityAbsolute
          locales {
            es
            en
          }
        }
        crop {
          id
          locales {
            es
            en
          }
        }
        location {
          coordinates
          type
        }
        riceLot {
          id
          emergenceDate
          emergenceUpdateType
          harvestDate
          harvestUpdateType
          isHarvested
          calendarV3(fromYearMonth: $fromYearMonth, toYearMonth: $toYearMonth) {
            date
            daysSinceEmergence
            mapUrls {
              chlorophyll {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
                indicator
                colorHistogram @include(if: $includeMapUrls) {
                  color
                  area
                }
              }
              irrigation {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
                indicator
                colorHistogram @include(if: $includeMapUrls) {
                  color
                  area
                }
              }
              realImage {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
              }
              grainHumidity {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
                emergenceDaysActivation
                colorHistogram @include(if: $includeMapUrls) {
                  color
                  area
                }
              }
              water {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
                emergenceDaysActivation
                colorHistogram @include(if: $includeMapUrls) {
                  color
                  area
                }
              }
              weeds {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
                emergenceDaysActivation
                colorHistogram @include(if: $includeMapUrls) {
                  color
                  area
                }
              }
              emergence {
                url @include(if: $includeMapUrls)
                isAvailable
                weatherType
                provider
                emergenceDaysActivation
                colorHistogram @include(if: $includeMapUrls) {
                  color
                  area
                }
              }
            }
          }
        }
      }
    }
  }
`
