import { useTheme } from 'styled-components'
import { List, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import { EllipsisText, IconText } from 'components'
import {
  cloudImage,
  partyallyCloudedWithCirrusImage,
  clearImage,
  satelliteImage,
} from 'assets/images'
import {
  WeatherDataObject,
  MapIndex,
  Lot,
  SatelliteProviders,
  EmergenceDaysActivationIndices,
  EmergenceDaysActivationIndex,
} from 'types'
import { SatelliteIcon } from 'assets/icons'

import {
  DescriptionCard,
  IndexInformationCard,
  SideBar,
  StyledCol,
  Image,
  Text,
} from './components'

interface Props {
  weatherDataForAllLots: WeatherDataObject
  selectedCalendarDate?: string
  selectedLots: Lot[]
  emergenceDaysActivationIndices: EmergenceDaysActivationIndices
  selectedMapIndex: MapIndex
  isShowDamsModeSelected: boolean
  weatherDataForDams: WeatherDataObject
}

export const RightBar: React.FC<Props> = ({
  weatherDataForAllLots,
  selectedCalendarDate,
  selectedLots,
  emergenceDaysActivationIndices,
  selectedMapIndex,
  isShowDamsModeSelected,
  weatherDataForDams,
}) => {
  const { t } = useTranslation('map')
  const { colors, foundation } = useTheme()

  const allLotstooltipMap = {
    CLEAR: {
      url: clearImage,
      text: t('calendar.tooltips.allLots.clear'),
    },
    CIRRUS: { url: partyallyCloudedWithCirrusImage, text: t('calendar.tooltips.allLots.cirrus') },
    PARTIALLY_CLOUDED: { url: cloudImage, text: t('calendar.tooltips.allLots.partiallyClouded') },
    PARTIALLY_CLOUDED_WITH_CIRRUS: {
      url: partyallyCloudedWithCirrusImage,
      text: t('calendar.tooltips.allLots.partiallyCloudedWithCirrus'),
    },
    CLOUDY: { url: cloudImage, text: t('calendar.tooltips.allLots.cloudy') },
    IMAGE_NOT_AVAILABLE: {
      url: satelliteImage,
      text: t('calendar.tooltips.allLots.imageNotAvailable'),
    },
  }

  const iconProvider: { [key in SatelliteProviders]: JSX.Element } = {
    ALL: <SatelliteIcon color={colors.white} />,
    SENTINEL2: <IconText letter={t('satelliteSelect.sentinelAbbreviation')} />,
    PLANET: <IconText letter={t('satelliteSelect.planetAbbreviation')} />,
    LANDSAT89: <IconText letter={t('satelliteSelect.landsatAbbreviation')} />,
  }

  const isEnabledForLots =
    selectedCalendarDate && !isShowDamsModeSelected && weatherDataForAllLots[selectedCalendarDate]
  const isEnabledForDams =
    isShowDamsModeSelected && selectedCalendarDate && weatherDataForDams[selectedCalendarDate]
  const dataSource = isShowDamsModeSelected ? weatherDataForDams : weatherDataForAllLots
  const isEnabled = isEnabledForLots || isEnabledForDams

  const indicesWithInformation = ['water', 'grainHumidity', 'weeds', 'emergence']

  const isInformationEnabled =
    indicesWithInformation.includes(selectedMapIndex) &&
    !!emergenceDaysActivationIndices[selectedMapIndex as EmergenceDaysActivationIndex] &&
    !isShowDamsModeSelected

  return (
    <SideBar>
      <StyledCol direction="vertical">
        <>
          {isEnabled && (
            <List
              style={{
                backgroundColor: colors.new.baseColor[900],
                padding: '10px 16px',
                maxHeight: '220px',
                overflowY: 'scroll',
                borderRadius: foundation.smallRadius,
              }}
              dataSource={dataSource[selectedCalendarDate]}
              renderItem={item => (
                <List.Item style={{ padding: '6px', borderBottom: `1px solid ${colors.grey1}` }}>
                  <Space>
                    <EllipsisText
                      text={item.name}
                      overlayInnerStyle={{
                        fontWeight: 'normal',
                        fontSize: '13px',
                        lineHeight: '24px',
                        width: '100px',
                        color: 'white',
                      }}
                    />
                    {item.satelliteProvider && iconProvider[item.satelliteProvider]}
                    <Image src={allLotstooltipMap[item.weatherType].url} />
                    <Text>{allLotstooltipMap[item.weatherType].text}</Text>
                  </Space>
                </List.Item>
              )}
            />
          )}
          {!!selectedLots.length && !isShowDamsModeSelected && (
            <DescriptionCard selectedMapIndex={selectedMapIndex} lots={selectedLots} />
          )}
          {isInformationEnabled && (
            <IndexInformationCard
              selectedMapIndex={selectedMapIndex as EmergenceDaysActivationIndex}
              emergenceDaysActivationIndices={emergenceDaysActivationIndices}
            />
          )}
        </>
      </StyledCol>
    </SideBar>
  )
}
