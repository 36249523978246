import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import useCustomCompareEffect from 'react-use/lib/useCustomCompareEffect'
import { isEqual, unionBy } from 'lodash'
import dayjs from 'dayjs'

import { MapContext } from 'features/MainRouter/contexts'
import { useQueryDams, useTrackEvent, useTrackImpression } from 'hooks'
import { ActiveCompanyContext } from 'contexts'
import { AmplitudeEventsName, DateState, MapIndex, Selects } from 'types'
import { Crop } from 'components/CropsSelect'
import { Variety } from 'components/VarietiesSelect/api'
import { Dam, Dams } from 'hooks/useQueryDams/api'

import { useLots } from './useLots'
import { Lot } from './useLots/api'
import { useRtkLots } from './useRtkLots'
import { useLotsFeatures } from './useLotsFeatures'
import { PopupInfo } from '../types'

// TODO: Move the logic from the map, that we think is important to be here, in order to optimize this component and make the code easier to read than it is.
// like: grainHumidityHasNotemErgenceDaysActivation, estimatedYieldHasNotFormula, formatTimeLineData etc..

interface Props {
  seasonId?: number
  fieldId?: number
  fieldName?: string
  isShowDamsModeSelected: boolean
}

const MAX_TIME_LIMIT_TO_FETCH_LOTS = 1000 * 60 * 5
const TIME_INTERVAL_FETCH_LOTS = 1000 * 60 * 0.5
const ONLY_ONE_LOT_SELECTED = 1

export const useMap = ({ seasonId, fieldId, fieldName, isShowDamsModeSelected }: Props) => {
  const { t } = useTranslation('common')
  const [selectedLots, setSelectedLots] = useState<Lot[]>([])
  const [isCalendarEmpty, setIsCalendarEmpty] = useState(true)
  const [isMaxTimeReached, setIsMaxTimeReached] = useState(false)
  const [isDisabledSeason, setIsDisabledSeason] = useState(false)
  const [selectedCropId, setSelectedCropId] = useState<number>()
  const [selectedVarietyId, setSelectedVarietyId] = useState<number | Selects.NO_VARIETY>()
  const [dams, setDams] = useState<Dam[]>([])
  const { track } = useTrackEvent(AmplitudeEventsName.INDEX_CHANGE)
  const { track: trackSelectedDateChange } = useTrackEvent(AmplitudeEventsName.SELECTED_DATE_CHANGE)
  const [popupInfo, setPopupInfo] = useState<PopupInfo>()

  const fetchLotsTimer: { current: NodeJS.Timeout | null } = useRef(null)

  const {
    selectedMapIndex,
    setSelectedMapIndex,
    selectedCalendarDate,
    selectedCompareMapIndex,
    setSelectedCalendarDate,
    selectedCompareCalendarDate,
    setSelectedCompareCalendarDate,
    dates,
    setDates,
    damDates,
    setDamDates,
    selectedDamCalendarDate,
    setSelectedDamCalendarDate,
    selectedSatellite,
    setSelectedSatellite,
    selectedCompareSatellite,
    setIsCompareCalendaropen,
    setIsCalendarOpen,
  } = MapContext.useMapContext()

  useEffect(() => {
    setSelectedLots([])
    setSelectedSatellite('ALL')
    setSelectedCalendarDate(undefined)
    setDates({
      fromYearMonth: undefined,
      toYearMonth: undefined,
      newDates: false,
    })
    setDamDates({
      fromYearMonth: undefined,
      toYearMonth: undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, seasonId])

  const { activeCompanyId, activeCompanyName } = ActiveCompanyContext.useActiveCompanyContext()

  useTrackImpression({
    eventName: AmplitudeEventsName.MAP_IMPRESSION,
    eventProperties: {
      activeCompanyId,
      activeCompanyName,
    },
    loading: !activeCompanyId,
  })

  const isOnlyOneLotSelected = selectedLots.length === ONLY_ONE_LOT_SELECTED

  const onLotWithoutCalendar = () => {
    setIsDisabledSeason(true)
  }

  const {
    lots,
    setLots,
    loading: isLotsLoading,
  } = useLots({
    seasonId,
    fromYearMonth: dates.fromYearMonth,
    toYearMonth: dates.toYearMonth,
    fieldId,
    pollInterval:
      !isDisabledSeason && isCalendarEmpty && !isMaxTimeReached ? TIME_INTERVAL_FETCH_LOTS : 0,
    setSelectedLots,
    setSelectedCropId,
    selectedLots,
    onLotWithoutCalendar,
  })

  const {
    rtkLots,
    loading: isRtkLoading,
    selectedLotsRtkList,
  } = useRtkLots({
    skip: isLotsLoading,
    seasonId,
    fieldId,
    selectedLots,
  })

  const onDamsCompleted = (data: Dams) => {
    if (!data) return
    const newDams: Dam[] =
      data?.dams.results?.map(queryDam => {
        const { calendar: queryCalendar, ...dam } = queryDam
        const mergedCalendar = unionBy(
          queryCalendar,
          dams.find(stateDam => stateDam?.id === queryDam.id)?.calendar ?? [],
          'date',
        )
        return {
          ...dam,
          calendar: mergedCalendar,
        }
      }) ?? []
    setDams(newDams)
  }

  const { loading: damsLoading } = useQueryDams({
    fieldId,
    includeCalendar: true,
    fromYearMonth: damDates.fromYearMonth,
    toYearMonth: damDates.toYearMonth,
    onCompleted: onDamsCompleted,
  })

  useEffect(() => {
    setLots([])
    setIsCalendarEmpty(true)
    setIsMaxTimeReached(false)
    fetchLotsTimer.current = setTimeout(() => {
      setIsMaxTimeReached(true)
    }, MAX_TIME_LIMIT_TO_FETCH_LOTS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonId])

  const {
    grainHumidityHasNotEmergenceDaysActivation,
    emergenceDaysActivationIndices,
    calendarDatesIndices,
    weatherDataForAllLots,
    isGrainHumidityAvailable,
    setCompareMapData,
    onHandleCompareSelectedMapIndexChange,
    isWaterAvailable,
    isWeedsAvailable,
    isEmergenceAvailable,
    damsCalendar,
    weatherDataForDams,
    compareCalendarDateIndices,
    handleCompareSelectedSatelliteChange,
    availableSatellites,
    handleSatelliteChange,
  } = useLotsFeatures({ selectedLots, dams, isShowDamsModeSelected, popupInfo, setPopupInfo })

  useEffect(() => {
    if (isLotsLoading || !lots.length) return
    if (calendarDatesIndices[selectedMapIndex].length) {
      setIsCalendarEmpty(false)
      setIsMaxTimeReached(true)
    }
  }, [calendarDatesIndices, lots, isLotsLoading, selectedMapIndex])

  useEffect(() => {
    if (isLotsLoading) return
    if (isDisabledSeason || isMaxTimeReached || !isCalendarEmpty) {
      clearTimeout(fetchLotsTimer.current as NodeJS.Timeout)
    }
    if (isDisabledSeason) return
    if (isMaxTimeReached && isCalendarEmpty) {
      notification.error({
        duration: 15,
        message: t('messages.genericErrorRefreshPage'),
      })
    }
  }, [isCalendarEmpty, isMaxTimeReached, isLotsLoading, t, isDisabledSeason])

  const interactiveMapLayersIds =
    useMemo(
      () =>
        isShowDamsModeSelected
          ? dams?.map(dam => dam.id.toString())
          : selectedLots.map(lot => lot.id.toString()),
      [selectedLots, dams, isShowDamsModeSelected],
    ) ?? []

  const onSelectedDefaultMapIndexChange = (index: MapIndex) => {
    if (!calendarDatesIndices[index].length) {
      setSelectedSatellite('ALL')
    }
    setSelectedMapIndex(index)
  }

  const onHandleSelectedMapIndexChange = (index: MapIndex, isCompareMap: boolean) => {
    track({
      index,
      fieldId,
      fieldName,
      activeCompanyId,
      activeCompanyName,
      isCompareMap,
    })

    return isCompareMap
      ? onHandleCompareSelectedMapIndexChange(index)
      : onSelectedDefaultMapIndexChange(index)
  }

  useCustomCompareEffect(
    () => {
      if (lots.length)
        track({
          index: selectedMapIndex,
          fieldId,
          fieldName,
          activeCompanyId,
          activeCompanyName,
          isCompareMap: false,
        })
    },
    [lots, fieldId],
    (prevDeps, nextDeps) => {
      const prevLotDeps = prevDeps?.[0] as Lot[] | undefined
      const nextLotDeps = nextDeps?.[0] as Lot[] | undefined

      const areDepsEqual =
        isEqual(prevLotDeps?.length, nextLotDeps?.length) && isEqual(prevDeps[1], nextDeps[1])

      return areDepsEqual
    },
  )

  const onHandleSelectedDateChange = (isCompareMap: boolean, date?: string) => {
    if (date) {
      trackSelectedDateChange({
        selectedDate: date,
        differenceInDaysUntilToday: dayjs().diff(dayjs(date)),
        lotName:
          selectedLots.length > ONLY_ONE_LOT_SELECTED ? 'Todos los lotes' : selectedLots[0].name,
        index: isCompareMap ? selectedCompareMapIndex : selectedMapIndex,
        fieldId,
        fieldName,
        activeCompanyId,
        activeCompanyName,
        isCompareMap,
      })
    }

    if (isShowDamsModeSelected) {
      return setSelectedDamCalendarDate(date)
    }

    return isCompareMap ? setSelectedCompareCalendarDate(date) : setSelectedCalendarDate(date)
  }

  const handleCropChange = (crop?: Crop) => {
    setSelectedVarietyId(undefined)
    if (!crop) {
      setSelectedLots(lots)
      setSelectedCropId(undefined)
      return
    }
    const filteredLots = lots.filter(lot => lot.crop.id === crop?.id)

    setSelectedLots(filteredLots)
    setSelectedCropId(crop?.id)
  }

  const handleLotsChange = useCallback((newLots: Lot[]) => {
    if (newLots.length === ONLY_ONE_LOT_SELECTED) {
      setSelectedLots(newLots)
      setSelectedCropId(newLots[0].crop.id)

      return
    }

    setSelectedLots(newLots)
  }, [])

  const handleVarietyChange = (variety?: Variety, isNoVarietySelected?: boolean) => {
    if (!variety) {
      setSelectedVarietyId(isNoVarietySelected ? Selects.NO_VARIETY : undefined)
      const filteredLotsWithoutVarietyByCopId = lots.filter(
        lot => lot.crop.id === selectedCropId && !lot.variety,
      )
      const filteredLotsWithVarietyByCopId = lots.filter(lot => lot.crop.id === selectedCropId)
      setSelectedLots?.(
        isNoVarietySelected ? filteredLotsWithoutVarietyByCopId : filteredLotsWithVarietyByCopId,
      )
      return
    }

    const filteredLotsWithVarietyByCopId = lots.filter(
      lot => lot.crop.id === selectedCropId && lot.variety?.id === variety.id,
    )
    setSelectedLots(filteredLotsWithVarietyByCopId)
    setSelectedVarietyId(variety.id)
  }

  const lotsOptions = useMemo(() => {
    if (!selectedCropId && !selectedVarietyId) return lots

    if (selectedVarietyId) {
      const filteredLotsWithoutVarietyByCopId = lots.filter(
        lot => lot.crop.id === selectedCropId && !lot.variety,
      )
      if (selectedVarietyId === Selects.NO_VARIETY) return filteredLotsWithoutVarietyByCopId

      return lots.filter(lot => lot.variety?.id === selectedVarietyId)
    }

    return lots.filter(lot => lot.crop.id === selectedCropId)
  }, [lots, selectedCropId, selectedVarietyId])

  const handleDatesChange = (newDates: React.SetStateAction<DateState>) => {
    if (isShowDamsModeSelected) {
      setDamDates(newDates)
      return
    }
    setDates(newDates)
  }

  const onCompareImagesMapClick = () => setIsCompareCalendaropen(false)

  return {
    isCalendarEmpty,
    calendarDatesIndices,
    dates,
    setDates: handleDatesChange,
    selectedCalendarDate: isShowDamsModeSelected ? selectedDamCalendarDate : selectedCalendarDate,
    setSelectedCalendarDate: (date?: string) => onHandleSelectedDateChange(false, date),
    selectedCompareCalendarDate,
    setSelectedCompareCalendarDate: (date?: string) => onHandleSelectedDateChange(true, date),
    selectedCompareMapIndex,
    setSelectedCompareMapIndex: (indicator: MapIndex) =>
      onHandleSelectedMapIndexChange(indicator, true),
    lots,
    isLotsLoading,
    rtkLots,
    isRtkLoading,
    selectedLotsRtkList,
    selectedLots,
    setSelectedLots,
    weatherDataForAllLots,
    selectedMapIndex,
    setSelectedMapIndex: (indicator: MapIndex) => onHandleSelectedMapIndexChange(indicator, false),
    grainHumidityHasNotEmergenceDaysActivation,
    emergenceDaysActivationIndices,
    setCompareMapData,
    interactiveMapLayersIds,
    isGrainHumidityAvailable,
    isWaterAvailable,
    isWeedsAvailable,
    isEmergenceAvailable,
    selectedCropId,
    handleCropChange,
    handleLotsChange,
    selectedLotId: isOnlyOneLotSelected ? selectedLots[0].id : undefined,
    isDisabledSeason,
    selectedVarietyId,
    handleVarietyChange,
    lotsOptions,
    setSelectedVarietyId,
    setSelectedCropId,
    dams,
    damsLoading,
    damsCalendar,
    weatherDataForDams,
    setDamDates,
    selectedSatellite,
    setSelectedSatellite,
    selectedCompareSatellite,
    compareCalendarDateIndices,
    handleCompareSelectedSatelliteChange,
    availableSatellites,
    popupInfo,
    setPopupInfo,
    handleSatelliteChange,
    onCompareImagesMapClick,
    setIsCalendarOpen,
  }
}
