import {
  WeatherDataObject,
  DateState,
  MapIndex,
  CalendarDatesMapIndices,
  Lot,
  CalendarDateIndex,
  SatelliteProviders,
  EmergenceDaysActivationIndices,
} from 'types'

import { TopBars, CompareTopBars } from './components'
import { AvailableSatellites } from '../../types'

interface Props {
  calendarDatesIndices: CalendarDatesMapIndices
  selectedCompareCalendarDate?: string
  selectedCompareMapIndex: MapIndex
  selectedMapIndex: MapIndex
  onHandleCompareMapIndexChange: (mapIndex: MapIndex) => void
  weatherDataForAllLots: WeatherDataObject
  onHandleMapIndexChange: (mapIndex: MapIndex) => void
  grainHumidityHasNotEmergenceDaysActivation: boolean
  emergenceDaysActivationIndices: EmergenceDaysActivationIndices
  isGrainHumidityAvailable: boolean
  isWaterAvailable: boolean
  isWeedsAvailable: boolean
  isEmergenceAvailable: boolean
  isCompareModeSelected: boolean
  onHandleCalendarDateChange: (date?: string) => void
  onHandleCompareCalendarDateChange: (date?: string) => void
  setDates: React.Dispatch<React.SetStateAction<DateState>>
  selectedCalendarDate?: string
  selectedLots: Lot[]
  isShowDamsModeSelected: boolean
  damsCalendar: CalendarDateIndex[]
  weatherDataForDams: WeatherDataObject
  onHandleSatelliteChange: (value: SatelliteProviders) => void
  selectedSatellite: SatelliteProviders
  onHandleCompareSatelliteChange: (value: SatelliteProviders) => void
  selectedCompareSatellite: SatelliteProviders
  compareCalendarDateIndices: CalendarDatesMapIndices
  availableSatellites: AvailableSatellites
}

export const TopSplitPanels: React.FC<Props> = ({
  calendarDatesIndices,
  selectedCompareCalendarDate,
  selectedCompareMapIndex,
  onHandleCompareMapIndexChange,
  onHandleCompareCalendarDateChange,
  weatherDataForAllLots,
  onHandleMapIndexChange,
  grainHumidityHasNotEmergenceDaysActivation,
  emergenceDaysActivationIndices,
  isGrainHumidityAvailable,
  isWaterAvailable,
  isWeedsAvailable,
  isEmergenceAvailable,
  selectedMapIndex,
  onHandleCalendarDateChange,
  setDates,
  selectedCalendarDate,
  selectedLots,
  isCompareModeSelected,
  isShowDamsModeSelected,
  damsCalendar,
  weatherDataForDams,
  onHandleSatelliteChange,
  selectedSatellite,
  onHandleCompareSatelliteChange,
  selectedCompareSatellite,
  compareCalendarDateIndices,
  availableSatellites,
}) => {
  return (
    <>
      {isCompareModeSelected ? (
        <CompareTopBars
          calendarDatesIndices={calendarDatesIndices}
          selectedCompareMapIndex={selectedCompareMapIndex}
          selectedCompareCalendarDate={selectedCompareCalendarDate}
          grainHumidityHasNotEmergenceDaysActivation={grainHumidityHasNotEmergenceDaysActivation}
          isWaterAvailable={isWaterAvailable}
          isGrainHumidityAvailable={isGrainHumidityAvailable}
          isWeedsAvailable={isWeedsAvailable}
          isEmergenceAvailable={isEmergenceAvailable}
          selectedMapIndex={selectedMapIndex}
          selectedCalendarDate={selectedCalendarDate}
          onHandleCompareMapIndexChange={onHandleCompareMapIndexChange}
          onHandleMapIndexChange={onHandleMapIndexChange}
          onHandleCalendarDateChange={onHandleCalendarDateChange}
          onHandleCompareCalendarDateChange={onHandleCompareCalendarDateChange}
          setDates={setDates}
          onHandleSatelliteChange={onHandleSatelliteChange}
          selectedSatellite={selectedSatellite}
          onHandleCompareSatelliteChange={onHandleCompareSatelliteChange}
          selectedCompareSatellite={selectedCompareSatellite}
          compareCalendarDateIndices={compareCalendarDateIndices}
          availableSatellites={availableSatellites}
        />
      ) : (
        <TopBars
          grainHumidityHasNotEmergenceDaysActivation={grainHumidityHasNotEmergenceDaysActivation}
          selectedMapIndex={selectedMapIndex}
          isWaterAvailable={isWaterAvailable}
          isGrainHumidityAvailable={isGrainHumidityAvailable}
          isWeedsAvailable={isWeedsAvailable}
          isEmergenceAvailable={isEmergenceAvailable}
          calendarDatesIndices={calendarDatesIndices}
          selectedCalendarDate={selectedCalendarDate}
          weatherDataForAllLots={weatherDataForAllLots}
          selectedLots={selectedLots}
          emergenceDaysActivationIndices={emergenceDaysActivationIndices}
          onHandleMapIndexChange={onHandleMapIndexChange}
          onHandleCalendarDateChange={onHandleCalendarDateChange}
          setDates={setDates}
          isShowDamsModeSelected={isShowDamsModeSelected}
          damsCalendar={damsCalendar}
          weatherDataForDams={weatherDataForDams}
          onHandleSatelliteChange={onHandleSatelliteChange}
          selectedSatellite={selectedSatellite}
          availableSatellites={availableSatellites}
        />
      )}
    </>
  )
}
