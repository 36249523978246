import { CalendarDate, Lot } from 'features/MainRouter/features/Map/hooks/useLots/api'

import { IndicatorStatus, SentinelHubProvider, WeatherType } from './graphql'
import { SatelliteProviders } from './selects'

export type { Lot, CalendarDate }

export type MapIndex =
  | 'irrigation'
  | 'chlorophyll'
  | 'realImage'
  | 'grainHumidity'
  | 'water'
  | 'weeds'
  | 'emergence'

export enum Indicators {
  IRRIGATION = 'IRRIGATION',
  CHLOROPHYLL = 'CHLOROPHYLL',
}

interface ColorHistogram {
  color: string
  area: number
}

export interface MapIndexInfo {
  url?: string
  isAvailable: boolean
  weatherType?: WeatherType
  provider: SentinelHubProvider
  emergenceDaysActivation?: number
  colorHistogram?: ColorHistogram[]
  indicator?: IndicatorStatus
}

export interface CalendarDateIndex {
  date: string
  mapIndexInfo: MapIndexInfo
}

export type CalendarDatesMapIndices = {
  [index in MapIndex]: CalendarDateIndex[]
}

export type CalendarDateIndexWithMultipleProviders = Omit<CalendarDateIndex, 'mapIndexInfo'> & {
  mapIndexInfo: MapIndexInfo[]
}

export type CalendarDatesMapIndicesWithMultipleProviders = {
  [index in MapIndex]: CalendarDateIndexWithMultipleProviders[]
}

export interface WeatherDataObject {
  [key: string]: {
    name: string
    weatherType: WeatherType | 'IMAGE_NOT_AVAILABLE'
    satelliteProvider?: SatelliteProviders
  }[]
}

export interface DateState {
  toYearMonth?: Date
  fromYearMonth?: Date
  newDates?: boolean
}

export type EmergenceDaysActivationIndex = Extract<
  MapIndex,
  'water' | 'grainHumidity' | 'weeds' | 'emergence'
>

export type EmergenceDaysActivationIndices = {
  [key in EmergenceDaysActivationIndex]: number
}
