import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMap as useReactMapGl, MapLayerMouseEvent, ViewStateChangeEvent } from 'react-map-gl'
import { parseInt as toInt } from 'lodash'
import bbox from '@turf/bbox'
import center from '@turf/center'
import centroid from '@turf/centroid'
import { points, polygon } from '@turf/helpers'
import MapboxCompare from 'mapbox-gl-compare'
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LANGUAGE, MAP } from 'consts'
import type { SelectField } from 'components/fields-selects/types'
import { ActiveCompanyContext } from 'contexts'
import { useHasCompanyOrFieldsOrLots, useQueryParams } from 'hooks'
import { LocaleLanguage } from 'types'
import { Header, SeasonSelect } from 'components'
import { GeoUtils } from 'utils'

import {
  SeasonFieldsSelect,
  LotsSelect,
  MapPopup,
  Toolbar,
  CompareImagesMap,
  MapContainer,
  MainContainer,
  Container,
  TopSplitPanels,
  DefaultMap,
  CropsSelect,
  VarietiesSelect,
} from './components'
import { PopupInfo } from './types'
import { useMap, useDrawingTool, useTools } from './hooks'
import { LotsUtils } from './utils'

const SELECT_NEW_UI_CLASSNAME = 'custom-select-dropdown'

export const Map: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('map')
  const { noLots, noFields, loading } = useHasCompanyOrFieldsOrLots()
  useEffect(() => {
    if (loading) return
    if (noFields || noLots) navigate('/dashboard', { replace: true })
  }, [loading, navigate, noFields, noLots])

  const { activeSeasonId, setActiveSeason } = ActiveCompanyContext.useActiveCompanyContext()
  const { map, compareMap } = useReactMapGl()
  const [cursor, setCursor] = useState<string>('grab')
  const [showRtk, setShowRtk] = useState(false)

  const [field, setField] = useState<SelectField>()

  const { setToolMode, toolMode, isCompareModeSelected, isShowDamsModeSelected } = useTools()

  const {
    isCalendarEmpty,
    calendarDatesIndices,
    dates,
    setDates,
    selectedCalendarDate,
    setSelectedCalendarDate,
    selectedCompareCalendarDate,
    setSelectedCompareCalendarDate,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    lots,
    isLotsLoading,
    rtkLots,
    isRtkLoading,
    selectedLotsRtkList,
    selectedLots,
    weatherDataForAllLots,
    selectedMapIndex,
    setSelectedMapIndex,
    grainHumidityHasNotEmergenceDaysActivation,
    emergenceDaysActivationIndices,
    setCompareMapData,
    interactiveMapLayersIds,
    isGrainHumidityAvailable,
    isWaterAvailable,
    isWeedsAvailable,
    isEmergenceAvailable,
    selectedCropId,
    selectedLotId,
    handleCropChange,
    handleLotsChange,
    isDisabledSeason,
    selectedVarietyId,
    handleVarietyChange,
    lotsOptions,
    setSelectedVarietyId,
    setSelectedCropId,
    dams,
    damsCalendar,
    weatherDataForDams,
    selectedSatellite,
    selectedCompareSatellite,
    handleCompareSelectedSatelliteChange,
    compareCalendarDateIndices,
    availableSatellites,
    popupInfo,
    setPopupInfo,
    handleSatelliteChange,
    onCompareImagesMapClick,
    setIsCalendarOpen,
  } = useMap({
    seasonId: activeSeasonId,
    fieldId: field?.id,
    fieldName: field?.name,
    isShowDamsModeSelected,
  })
  const query = useQueryParams()
  const defaultFieldId = toInt(query.get('fieldId') ?? '')
  const defaultLotId = toInt(query.get('lotId') ?? '')

  const [viewport, setViewport] = useState({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    zoom: MAP.ZOOM.DEFAULT,
  })

  const onChangeLotTransition = useCallback(
    ({ longitude, latitude, zoom }: { longitude?: number; latitude?: number; zoom?: number }) => {
      if (!longitude || !latitude) return
      map?.flyTo({
        center: [longitude, latitude],
        duration: MAP.DEFAULT_TRANSITION.transitionDuration,
        zoom,
        essential: true,
      })
    },
    [map],
  )

  const lotTransitionTimer: { current: NodeJS.Timeout | null } = useRef(null)

  useEffect(() => {
    return () => (lotTransitionTimer.current ? clearTimeout(lotTransitionTimer.current) : undefined)
  }, [])

  useEffect(() => {
    if (selectedLots.length) {
      const locationsCoordinates = selectedLots.map(lot => lot.location.coordinates)
      const features = points(locationsCoordinates)
      const lotsCenter = center(features)
      // this conditionals are in order to set close zoom and move lot only when user change lot and not when get more dates
      const latitude = !dates.newDates ? lotsCenter.geometry.coordinates[1] : viewport.latitude
      const longitude = !dates.newDates ? lotsCenter.geometry.coordinates[0] : viewport.longitude
      const zoom =
        viewport.zoom === MAP.ZOOM.DEFAULT || !dates.newDates ? MAP.ZOOM.CLOSE : viewport.zoom
      if (map) {
        // This time out is to prevent interruption of transitions when switching between companies.
        lotTransitionTimer.current = setTimeout(() => {
          onChangeLotTransition({ longitude, latitude, zoom })
        }, 0)
      }
      setDates(prevDates => ({
        ...prevDates,
        newDates: false,
      }))
      setPopupInfo(undefined)
    }

    return () => (lotTransitionTimer.current ? clearTimeout(lotTransitionTimer.current) : undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLots, map])

  const {
    drawnAreas,
    drawMode,
    onCreateFeature,
    onUpdateFeature,
    onDeleteAllFeatures,
    onDeleteFeature,
    drawnAreasFeatures,
    drawingOption,
    onDrawingOptionSelected,
    showDropdown,
    toggleDropdownVisibility,
  } = useDrawingTool(toolMode, setToolMode)

  const [popupCoordinates, setPopupCoordinates] = useState<number[]>()

  const handleChange = useCallback(
    (newField: SelectField) => {
      setField(newField)
      onDeleteAllFeatures()
      setSelectedVarietyId(undefined)
      setSelectedCropId(undefined)
    },
    [onDeleteAllFeatures, setSelectedVarietyId, setSelectedCropId],
  )

  const onMove = useCallback((evt: ViewStateChangeEvent) => setViewport(evt.viewState), [])

  // This is a workaround to track when we should show popup or not based on if we are drawing or deleting with the drawing tool
  const [showPopup, setShowPopup] = useState(true)

  const onMouseEnter = useCallback(() => {
    setShowPopup(drawingOption !== 'draw' && drawingOption !== 'delete')
    setCursor('pointer')
  }, [drawingOption])

  const onMouseLeave = useCallback(() => setCursor('grab'), [])

  const setDamPopupInfo = useCallback(
    (id: string, damCalendarDate?: string) => {
      const dam = dams?.find(damToFind => damToFind.id === Number(id))
      const calendarDate = dam?.calendar.find(
        date => date.date === (damCalendarDate ?? selectedCalendarDate),
      )
      if (!calendarDate || !dam) return

      const bboxLot = GeoUtils.bboxCoords(bbox(dam.area))
      const polygonLot = polygon(dam.area.coordinates)
      const polygonCenter = centroid(polygonLot)
      setPopupCoordinates([polygonCenter.geometry.coordinates[0], bboxLot[0][1] + 0.003])
      setCursor('grab')

      setPopupInfo({
        name: dam.name,
        id: dam.id,
        area: calendarDate.mapUrls.waterSurface?.area ?? undefined,
        volume: calendarDate.mapUrls.waterSurface?.volume ?? undefined,
        percentage: calendarDate.mapUrls.waterSurface?.percentage ?? undefined,
        resource: 'dam',
      })
    },
    [dams, selectedCalendarDate, setPopupInfo],
  )

  const onDefaultMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      setIsCalendarOpen(false)
      if (popupInfo) {
        setPopupInfo(undefined)
        return
      }

      const { features } = event

      const feature = features?.[0]
      if (!feature) return

      const { id } = feature.layer

      if (isShowDamsModeSelected) {
        setDamPopupInfo(id)
        return
      }

      const lot = selectedLots?.find(lotToFind => lotToFind.id === Number(id))
      if (!lot) return

      const isHarvested = !!lot.riceLot.isHarvested

      const calendarDateInfo = lot.riceLot.calendar.find(
        calendarDate => calendarDate.date === selectedCalendarDate,
      )

      const irrigationIndicator = LotsUtils.getCalendarDateByProvider(
        selectedSatellite,
        'irrigation',
        calendarDateInfo?.mapUrls,
      )

      const chlorophyllIndicator = LotsUtils.getCalendarDateByProvider(
        selectedSatellite,
        'chlorophyll',
        calendarDateInfo?.mapUrls,
      )

      const bboxLot = GeoUtils.bboxCoords(bbox(lot.area))
      const polygonLot = polygon(lot.area.coordinates)
      const polygonCenter = centroid(polygonLot)
      setPopupCoordinates([polygonCenter.geometry.coordinates[0], bboxLot[0][1] + 0.003])
      setCursor('grab')
      const language = LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]
      setPopupInfo({
        name: lot.name,
        daysSinceEmergencyDate: calendarDateInfo?.daysSinceEmergence ?? undefined,
        variety: lot.variety?.locales[language],
        size: lot.size,
        irrigationIndicator: irrigationIndicator?.indicator,
        chlorophyllIndicator: chlorophyllIndicator?.indicator,
        isHarvested,
        id: lot.id,
        crop: lot.crop.locales[language],
        resource: 'lot',
      })
    },
    [
      setIsCalendarOpen,
      popupInfo,
      isShowDamsModeSelected,
      selectedLots,
      selectedSatellite,
      setPopupInfo,
      setDamPopupInfo,
      selectedCalendarDate,
    ],
  )

  const compareMapRef = useRef<MapboxCompare>()

  const onHandleCompareImages = () => {
    setToolMode(prevMode => (prevMode === 'compare' ? null : 'compare'))
  }

  const handleDams = () => {
    setShowPopup(false)
    setPopupInfo(undefined)
    setToolMode(prevMode => (prevMode === 'show-dams' ? null : 'show-dams'))
  }

  useEffect(() => {
    if (!isCompareModeSelected) {
      compareMapRef.current?.remove()
    }
  }, [isCompareModeSelected])

  const onCompareMapRender = () => {
    if (!map || !compareMap) return
    setCompareMapData()
    compareMapRef.current = new MapboxCompare(compareMap, map, '#comparison-container')
  }

  const handleSeasonChange = (id?: number) => {
    setField(undefined)
    setActiveSeason(id)
  }

  const headerItems = [
    <SeasonSelect onChange={handleSeasonChange} />,
    <SeasonFieldsSelect
      defaultId={Number.isNaN(defaultFieldId) ? undefined : defaultFieldId}
      onChange={handleChange}
      dropdownClassName={SELECT_NEW_UI_CLASSNAME}
      popupMatchSelectWidth={false}
    />,
    <CropsSelect
      dropdownClassName={SELECT_NEW_UI_CLASSNAME}
      popupMatchSelectWidth={false}
      value={selectedCropId}
      withAllCropsOption
      onChange={handleCropChange}
      supportedCropIds={lots.map(lot => lot.crop.id)}
      disabled={isShowDamsModeSelected}
    />,
    <VarietiesSelect
      dropdownClassName={SELECT_NEW_UI_CLASSNAME}
      popupMatchSelectWidth={false}
      value={selectedVarietyId}
      withAllVarietiesOption
      onChange={handleVarietyChange}
      disabled={!selectedCropId || isShowDamsModeSelected}
      cropId={selectedCropId}
      supportedVarietiesIds={lots
        .filter(lot => {
          if (!selectedCropId) return true
          return lot.crop.id === selectedCropId
        })
        .map(lot => lot.variety?.id)}
    />,
    <LotsSelect
      fieldId={field?.id}
      defaultId={Number.isNaN(defaultLotId) ? undefined : defaultLotId}
      onChange={handleLotsChange}
      lots={lotsOptions}
      loading={isLotsLoading}
      dropdownClassName={SELECT_NEW_UI_CLASSNAME}
      popupMatchSelectWidth={false}
      value={selectedLotId}
      disabled={isShowDamsModeSelected}
    />,
  ]

  const onHandleCalendarDateChange = (date?: string) => {
    setSelectedCalendarDate(date)

    if (!popupInfo?.id) return

    if (popupInfo.resource === 'dam') {
      setDamPopupInfo(popupInfo.id.toString(), date)
      return
    }

    const lot = selectedLots?.find(lotToFind => lotToFind.id === Number(popupInfo.id))

    if (!lot) return

    const newDate = lot.riceLot.calendar.find(calendarDate => calendarDate.date === date)

    const irrigationIndicator = LotsUtils.getCalendarDateByProvider(
      selectedSatellite,
      'irrigation',
      newDate?.mapUrls,
    )

    const chlorophyllIndicator = LotsUtils.getCalendarDateByProvider(
      selectedSatellite,
      'chlorophyll',
      newDate?.mapUrls,
    )
    setPopupInfo(prevState => {
      const newInfo = {
        ...prevState,
        irrigationIndicator: irrigationIndicator?.indicator,
        chlorophyllIndicator: chlorophyllIndicator?.indicator,
        daysSinceEmergencyDate: newDate?.daysSinceEmergence ?? undefined,
      } as PopupInfo

      return newInfo
    })
  }

  return (
    <MainContainer>
      <Header items={headerItems} />
      <Container>
        {(isDisabledSeason || isLotsLoading || isCalendarEmpty) && (
          <MapPopup
            type={isDisabledSeason ? 'text' : 'datesLoader'}
            position={isCompareModeSelected ? 'top' : 'bottom'}
            text={t('disabledSeason.text')}
          />
        )}

        <TopSplitPanels
          calendarDatesIndices={calendarDatesIndices}
          selectedCompareCalendarDate={selectedCompareCalendarDate}
          selectedCompareMapIndex={selectedCompareMapIndex}
          onHandleCompareMapIndexChange={setSelectedCompareMapIndex}
          weatherDataForAllLots={weatherDataForAllLots}
          onHandleMapIndexChange={setSelectedMapIndex}
          grainHumidityHasNotEmergenceDaysActivation={grainHumidityHasNotEmergenceDaysActivation}
          emergenceDaysActivationIndices={emergenceDaysActivationIndices}
          isGrainHumidityAvailable={isGrainHumidityAvailable}
          isWaterAvailable={isWaterAvailable}
          isWeedsAvailable={isWeedsAvailable}
          isEmergenceAvailable={isEmergenceAvailable}
          onHandleCompareCalendarDateChange={setSelectedCompareCalendarDate}
          selectedMapIndex={selectedMapIndex}
          setDates={setDates}
          onHandleCalendarDateChange={onHandleCalendarDateChange}
          selectedCalendarDate={selectedCalendarDate}
          selectedLots={selectedLots}
          isCompareModeSelected={isCompareModeSelected}
          isShowDamsModeSelected={isShowDamsModeSelected}
          damsCalendar={damsCalendar}
          weatherDataForDams={weatherDataForDams}
          onHandleSatelliteChange={handleSatelliteChange}
          selectedSatellite={selectedSatellite}
          onHandleCompareSatelliteChange={handleCompareSelectedSatelliteChange}
          selectedCompareSatellite={selectedCompareSatellite}
          compareCalendarDateIndices={compareCalendarDateIndices}
          availableSatellites={availableSatellites}
        />
        <Toolbar
          mode={toolMode}
          onHandleCompareImages={onHandleCompareImages}
          drawingOption={drawingOption}
          showDrawingToolDropdown={showDropdown}
          onDrawingToolDropdownVisibleChange={toggleDropdownVisibility}
          onDrawingOptionSelected={onDrawingOptionSelected}
          isDrawingToolAvailable={!drawnAreas.length}
          lots={selectedLotsRtkList}
          showRtk={showRtk}
          isRtkLoading={isRtkLoading}
          toggleShowRtk={(show?: boolean) =>
            show ? setShowRtk(true) : setShowRtk(prevState => !prevState)
          }
          isShowDamsModeSelected={isShowDamsModeSelected}
          handleDams={handleDams}
          isDamsToolAvailable={!!dams?.length}
        />

        <MapContainer id="comparison-container">
          <DefaultMap
            onHandleMove={onMove}
            popupInfo={popupInfo}
            interactiveMapLayersIds={interactiveMapLayersIds}
            isCompareModeSelected={isCompareModeSelected}
            viewport={viewport}
            onHandleClick={onDefaultMapClick}
            onHandleMouseLeave={onMouseLeave}
            onHandleMouseEnter={onMouseEnter}
            drawMode={drawMode}
            cursor={cursor}
            onHandleCreateFeature={onCreateFeature}
            onHandleUpdateFeature={onUpdateFeature}
            onHandleDeleteFeature={onDeleteFeature}
            drawnAreasFeatures={drawnAreasFeatures}
            drawingOption={drawingOption}
            selectedCalendarDate={selectedCalendarDate}
            selectedLots={selectedLots}
            rtkLots={rtkLots}
            selectedMapIndex={selectedMapIndex}
            drawnAreas={drawnAreas}
            toolMode={toolMode}
            showRtk={showRtk}
            showPopup={showPopup}
            popupCoordinates={popupCoordinates}
            dams={dams ?? []}
            isShowDamsModeSelected={isShowDamsModeSelected}
            selectedSatellite={selectedSatellite}
          />
          {isCompareModeSelected && (
            <CompareImagesMap
              lots={selectedLots}
              initialViewPort={{ ...viewport }}
              onMount={onCompareMapRender}
              showRtk={!drawnAreas.length && toolMode !== 'draw' && showRtk}
              rtkLots={rtkLots}
              onClick={onCompareImagesMapClick}
            />
          )}
        </MapContainer>
      </Container>
    </MainContainer>
  )
}
