import { Colors } from 'styles/theme/colors'

export const BOX_COLORS = {
  IRRIGATION: {
    levelOne: '#0E2BFF',
    levelTwo: '#33E3FF',
    levelThree: '#FCFF1F',
    levelFour: '#F5B708',
    levelFive: '#8D5F0A',
  },
  CHLOROPHYLL: {
    levelOne: '#00BBF6',
    levelTwo: '#0E7115',
    levelThree: '#43D341',
    levelFour: '#FAFF00',
    levelFive: '#A51414',
    levelSix: '#AB9797',
    levelSeven: '#330000',
  },
  WATER: {
    levelOne: '#0E2BFF',
    levelTwo: '#10A2A9',
    levelThree: '#33E3FF',
    levelFour: '#FCFF1F',
    levelFive: '#F5B708',
    levelSix: '#8D5F0A',
  },
  WEEDS: {
    levelThree: Colors.new.trafficLightRed,
    levelTwo: Colors.new.trafficLightYellow,
    levelOne: Colors.new.trafficLightGreen,
  },
  EMERGENCE: {
    levelOne: '#044703',
    levelTwo: '#43d341',
    levelThree: '#faff00',
    levelFour: '#834124',
    levelFive: '#330000',
  },
}
