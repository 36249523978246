import { Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EmergenceDaysActivationIndices } from 'types'

const { Text: AntText } = Typography

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
  padding: 20px 16px;
  display: flex;
  justify-content: center;
`

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
`
interface Props {
  selectedMapIndex: 'water' | 'grainHumidity' | 'weeds' | 'emergence'
  emergenceDaysActivationIndices: EmergenceDaysActivationIndices
}

export const IndexInformationCard: React.FC<Props> = ({
  selectedMapIndex,
  emergenceDaysActivationIndices,
}) => {
  const { t } = useTranslation('map')

  const informationText = {
    water: t('indicators.information.water', {
      days: emergenceDaysActivationIndices.water,
    }),
    grainHumidity: t('indicators.information.grainHumidity', {
      days: emergenceDaysActivationIndices.grainHumidity,
    }),
    weeds: t('indicators.information.weeds', {
      days: emergenceDaysActivationIndices.weeds,
    }),
    emergence: t('indicators.information.emergence', {
      days: emergenceDaysActivationIndices.emergence,
    }),
  }

  return (
    <Card>
      <Text>{informationText[selectedMapIndex]}</Text>
    </Card>
  )
}
