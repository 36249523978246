import { Space } from 'antd'

import {
  DateState,
  MapIndex,
  CalendarDatesMapIndices,
  CalendarDateIndex,
  SatelliteProviders,
} from 'types'
import { AvailableSatellites } from 'features/MainRouter/features/Map/types'

import { Calendar, Container } from './components'
import { SatelliteInformationSelect } from '../../../SatelliteInformationSelect'
import { SatelliteSelect } from '../../../SatelliteSelect'

interface Props {
  grainHumidityHasNotEmergenceDaysActivation: boolean
  onHandleMapIndexChange: (mapIndex: MapIndex) => void
  selectedMapIndex: MapIndex
  isWaterAvailable: boolean
  isGrainHumidityAvailable: boolean
  isWeedsAvailable: boolean
  isEmergenceAvailable: boolean
  onHandleCalendarDateChange: (date?: string) => void
  setDates: React.Dispatch<React.SetStateAction<DateState>>
  calendarDatesIndices: CalendarDatesMapIndices
  selectedCalendarDate?: string
  isShowDamsModeSelected: boolean
  damsCalendar: CalendarDateIndex[]
  onHandleSatelliteChange: (value: SatelliteProviders) => void
  selectedSatellite: SatelliteProviders
  availableSatellites: AvailableSatellites
}
export const LeftBar: React.FC<Props> = ({
  grainHumidityHasNotEmergenceDaysActivation,
  onHandleMapIndexChange,
  selectedMapIndex,
  isWaterAvailable,
  isGrainHumidityAvailable,
  isWeedsAvailable,
  isEmergenceAvailable,
  onHandleCalendarDateChange,
  setDates,
  calendarDatesIndices,
  selectedCalendarDate,
  isShowDamsModeSelected,
  damsCalendar,
  onHandleSatelliteChange,
  selectedSatellite,
  availableSatellites,
}) => {
  return (
    <Container>
      <Space direction="vertical" size={11}>
        {!isShowDamsModeSelected && (
          <>
            <SatelliteInformationSelect
              width="220px"
              grainHumidityHasNotEmergenceDaysActivation={
                grainHumidityHasNotEmergenceDaysActivation
              }
              onChange={onHandleMapIndexChange}
              selectedMapIndex={selectedMapIndex}
              isWaterAvailable={isWaterAvailable}
              isGrainHumidityAvailable={isGrainHumidityAvailable}
              isWeedsAvailable={isWeedsAvailable}
              isEmergenceAvailable={isEmergenceAvailable}
            />
            <SatelliteSelect
              width="220px"
              onChange={onHandleSatelliteChange}
              selectedSatellite={selectedSatellite}
              isPlanetAvailable={availableSatellites.planet}
              isLandsatAvailable={availableSatellites.landsat}
              isSentinelAvailable={availableSatellites.sentinel}
            />
          </>
        )}

        <Calendar
          calendarDates={
            isShowDamsModeSelected ? damsCalendar : calendarDatesIndices[selectedMapIndex]
          }
          defaultValue={selectedCalendarDate}
          onChange={onHandleCalendarDateChange}
          setDates={setDates}
          isShowDamsModeSelected={isShowDamsModeSelected}
        />
      </Space>
    </Container>
  )
}
